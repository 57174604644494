import PlayBtn from "images/PlayBtn.png";
import PlayBtnHover from "images/PlayBtnHover.png";
import React, { useEffect, useMemo, useRef, useState } from "react";
import VirtualTourBackground from "images/Matterport-Austin-Thumbnail.jpg";
import caratRight from "images/carat-right-0cbd96.svg";
import caratRightHover from "images/carat-right-0fd9ac.svg";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import useSize from "@react-hook/size";
import { FullBackground, Layout, PageContainer, SEO } from "components";
import { device, routes } from "utils";

const S = {};

const VirtualTour = ({ location }) => {
  const title = "Virtual Tour";
  const description =
    "Take a virtual walk through our life science cloud lab facility";
  const frameRef = useRef(null);
  const [hovered, setBtnHovered] = useState(false);
  const [playing, setPlaying] = useState(false);

  const containerRef = useRef(null);
  const [containerWidth] = useSize(containerRef);

  const viewerDimensions = useRef({
    viewerMaxWidth: 900,
    viewerMaxHeight: 507,
    viewerHeightToWidthRatio: 507 / 900,
  });
  useEffect(() => {
    const convertRemToPixels = (rem) =>
      rem *
      parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    viewerDimensions.current = {
      maxWidth: convertRemToPixels(90),
      maxHeight: convertRemToPixels(50.7),
      heightToWidthRatio: convertRemToPixels(50.7) / convertRemToPixels(90),
    };
  }, []);

  useEffect(() => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-441213032/Bu2SCJmL7_IBEOjAsdIB",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const { viewerWidth, viewerHeight } = useMemo(() => {
    if (containerWidth >= viewerDimensions.current.maxWidth) {
      return {
        viewerWidth: viewerDimensions.current.maxWidth,
        viewerHeight: viewerDimensions.current.maxHeight,
      };
    }
    const viewerWidth = containerWidth;
    const viewerHeight =
      Math.floor(containerWidth * viewerDimensions.current.heightToWidthRatio) +
      1;
    return { viewerWidth, viewerHeight };
  }, [containerWidth, viewerDimensions]);

  // MatterPort URL parameters
  // https://support.matterport.com/hc/en-us/articles/209980967-URL-Parameters
  const matterPortParams = [
    "m=hb93UoyDCEd", // Video ID
    "brand=0", // No company info in upper left
    "hl=0", // No highlight reel
    "mt=0", // Hide Mattertags and "Mattertag™ Content" toggle in the "About" panel
    "gt=1", // Hide Guided Tour buttons in bottom left corner
    "mls=2", // MLS-friendly. No About panel, no VR, no Mattertag Posts
    "hr=0", // Hide highlight reel at bottom
    "vr=0", // No VR link
    "help=0", // No Help link
    "play=1", // Start playing immediately
    "qs=0", // No QuickStart (show the 3d zoom in first)
    "dh=1", // Show Dollhouse view on initial fly-in
    "title=0", // Don't show space title ('ECL1 Lab Tour MM/DD/YYY')
  ].join("&");

  return (
    <FullBackground gradient>
      <Layout clearNav location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <S.PageContainer>
          <S.Heading>
            <S.MainTitle>Take a Virtual Tour of ECL</S.MainTitle>
          </S.Heading>
          <S.Viewer ref={containerRef}>
            {playing ? (
              <iframe
                ref={frameRef}
                title="virtual-tour"
                width={viewerWidth}
                height={viewerHeight}
                src={`https://my.matterport.com/show/?${matterPortParams}`}
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                onLoad={() => frameRef.current.focus()}
              />
            ) : (
              <S.Preview
                onClick={() => setPlaying(true)}
                viewerWidth={viewerWidth}
                viewerHeight={viewerHeight}
              >
                <S.PreviewImage
                  src={VirtualTourBackground}
                  alt="Start virtual tour"
                  width={viewerWidth}
                />
                <S.PreviewImageDimmer />
                <S.PlayControl>
                  <S.PlayButton
                    src={hovered ? PlayBtnHover : PlayBtn}
                    onMouseEnter={() => setBtnHovered(true)}
                    onMouseLeave={() => setBtnHovered(false)}
                    alt="Play"
                    title="Play"
                    loading="lazy"
                  />
                  <S.PlayButtonText>START TOUR</S.PlayButtonText>
                </S.PlayControl>
              </S.Preview>
            )}
          </S.Viewer>
          <S.ContactBanner>
            <p>Want to see a live technology demonstration?</p>
            <a
              href={routes("contactUs")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.ActionButton>GET STARTED</S.ActionButton>
            </a>
          </S.ContactBanner>
        </S.PageContainer>
      </Layout>
    </FullBackground>
  );
};

export default VirtualTour;

S.PageContainer = styled(PageContainer)`
  border-top: 1px solid #53575b;
`;

S.MainTitle = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.6rem;
  margin-bottom: 1rem;
`;

S.Heading = styled.div`
  letter-spacing: 0.07rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;
`;

S.Viewer = styled.div`
  min-height: 10rem;
  min-width: 24rem;
  display: flex;
  justify-content: center;
`;

// Use attrs because this value could be changing rapidly. It will create
// a zillion separate styles unless we just attach in a style prop.
// It might be better to just set this style prop above in the component.
// It doesn't really need to be right here.
S.Preview = styled.div.attrs((props) => {
  const { viewerWidth, viewerHeight } = props;
  const style = {
    width: `${viewerWidth}px`,
    height: `${viewerHeight}px`,
  };
  return { style };
})`
  min-width: 24rem;
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

S.PreviewImage = styled.img`
  position: absolute;
  top: 0;
  // left: 0;
  // right: 0;
  bottom: 0;
  margin: 0 auto;
`;

S.PreviewImageDimmer = styled.div`
  background-color: black;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
`;

S.PlayControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

S.PlayButton = styled.img`
  cursor: pointer;
`;

S.PlayButtonText = styled.div`
  flex: 0 0 auto;
  color: white;
  font-size: 2.4rem;
  font-weight: 600;
  margin-top: 1rem;
`;

S.Link = styled.a`
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 0 0 4rem;
  width: 21.2rem;
  &:hover {
    .carat-right {
      background: url(${caratRightHover}) no-repeat center / 80%;
    }
  }
  .carat-right {
    width: 2rem;
    height: 2rem;
    background: url(${caratRight}) no-repeat center / 80%;
  }
  @media ${device.xl} {
    margin: 0 2.3rem 6rem;
  }
`;

S.ContactBanner = styled.div`
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
  margin: 0 0 3rem;
  text-align: center;
  max-width: 90rem;
  p {
    margin: 0 0 1rem 0;
    font-size: 1.7rem;
    font-weight: 500;
    color: ${({ theme }) => theme.white};
  }

  // Margin at device.xl breakpoint can be fiddled to be better
  @media ${device.sm} {
    flex-direction: row;
    p {
      margin: 0;
    }
  }
  @media ${device.lg} {
    padding: 2rem 3rem;
  }
  @media ${device.xl} {
    margin: 0 2.4rem 5rem;
    min-width: 90rem;
  }
`;

S.ActionButton = styled.div`
  cursor: pointer;
  padding: 0.5rem 1.4rem;
  border: 1px solid #b1bac2;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  &:hover {
    background-color: #000000;
    border-color: #ffffff;
  }
`;
